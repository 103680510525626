.messagesBlock {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;

  .scrollBlock {
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.messageContainer {
  width: calc(100% - 20px);
  margin-bottom: 15px;
  display: flex;
  padding-right: 20px;

  &:first-child {
    margin-bottom: 0;
  }

  &__myMessage {
    justify-content: flex-end;
    padding-right: 0;
    padding-left: 20px;
  }

  .message {
    width: fit-content;
    max-width: calc(100% - 30px - 18px);
    overflow-wrap: break-word;
    position: relative;
    padding: 8px;
    background: #e0f2fe;
    border-radius: 8px;
    padding-right: 40px;

    &__notMy {
      padding-top: 4px;
    }

    .userName {
      font-size: 14px;
      margin-bottom: 4px;
      color: #4b5563;
    }

    .content {
      white-space: pre-line;
    }

    .time {
      width: 40px;
      height: 22px;
      font-size: 11px;
      color: #9ca3af;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .photosBlock {
      margin-bottom: 10px;

      &__notContentBottom {
        margin-bottom: 0;
      }

      .photosItem {
        width: 100%;
        margin-bottom: 12px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        .img {
          width: 100%;
          cursor: pointer;
        }
      }
    }

    .documentsBlock {
      margin-bottom: 6px;

      &__notText {
        margin-bottom: 0;
      }

      .ducumentsItem {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .image {
          width: 24px;
          height: 24px;
          background: #3b82f6;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .name {
          width: calc(100% - 24px - 24px - 20px);
          padding: 0 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .downloadFile {
          width: 24px;
          height: 24px;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }
}

.bottomBlock {
  border-top: #e5e5e5 1px solid;
  background: #f5f5f5;
  padding-bottom: 12px;

  .attachmentsBlockContainer {
    display: flex;
    position: relative;
  }

  .row {
    margin-top: 12px;
    display: flex;
    align-items: flex-end;

    .textareaConteiner {
      width: calc(100% - 24px - 54px - 54px);
      border: #e5e5e5 1px solid;
      border-radius: 8px;
      min-height: 21px;
      padding: 10px 12px;
      background: white;
      cursor: text;

      .textarea {
        max-height: 132px;
        width: 100%;
        margin: 0;
        font-size: 14px;
        resize: none;
        border: none;
        outline: none;
      }
    }

    .button {
      width: 54px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      position: relative;

      &:disabled {
        cursor: default;
      }
    }
  }
}

.toolsPupop {
  width: 120px;
  cursor: default;
  border: #e5e5e5 1px solid;
  border-radius: 6px;
  background: white;
  position: absolute;
  bottom: 50px;
  left: 0;
  padding: 6px;

  .item {
    width: calc(100% - 8px);
    height: 30px;
    padding: 0 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    @media (hover: hover) {
      &:hover {
        background: #f5f5f5;
      }
    }

    .imageBlock {
      width: 18px;
      height: 18px;
      margin-right: 6px;
    }

    .inputFile {
      display: none;
    }
  }
}

.toggleOpenAttachmentsBlock {
  width: 32px;
  height: 24px;
  position: absolute;
  top: -25px;
  border: none; //#e5e5e5 1px solid;
  border-radius: 6px 6px 0 0;
  background: #f5f5f5;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
}

.fakeAttachmentsBlock {
  width: 100%;
  border-bottom: #e5e5e5 1px solid;
  height: 10px;
}

.attachmentsBlock {
  width: 100%;
  border-bottom: #e5e5e5 1px solid;

  .photosBlock {
    overflow-y: auto;
    display: flex;

    .photosScrollBlock {
      display: flex;
      padding: 18px 18px 0 18px;

      .photosItem {
        height: 70px;
        margin-right: 12px;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        .img {
          width: auto;
          height: 100%;
          cursor: pointer;
        }

        .deleteButton {
          width: 20px;
          height: 20px;
          position: absolute;
          right: -8px;
          top: -8px;
          border: #e5e5e5 1px solid;
          background: white;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          position: absolute;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ducumentsBlock {
    width: calc(100% - 24px);
    padding: 12px;

    .ducumentsItem {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .image {
        width: 24px;
        height: 24px;
        background: #3b82f6;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .name {
        width: calc(100% - 24px - 24px - 20px);
        padding: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .buttonDelete {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
