.content {
  width: 300px;

  .item {
    margin-bottom: 14px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .button {
      margin-top: 14px;
      width: calc(50% - 5px);

      &__red {
        color: red;
        border-color: red;
      }
    }
  }
}
