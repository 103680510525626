.container {
  width: 160px;

  .type {
    width: fit-content;
    padding: 4px 8px;
    border-radius: 8px;

    &__now {
      background: #fecaca;
    }

    &__today {
      background: #fed7aa;
    }

    &__threeDays {
      background: #fef08a;
    }

    &__custom {
      background: #bae6fd;
    }
  }
}
