.container {
  width: 100%;
  height: calc(100vh - 74px - 30px - 60px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 940px) {
    overflow: auto;
  }

  .content {
    height: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;

    @media (max-width: 940px) {
      width: 200%;
    }
  }
}

.columnItem {
  display: flex;
  align-items: center;
  padding-right: 60px;
  position: relative;
  height: 32px;
  padding: 5px 10px;
  border-radius: 10px;

  @media (hover: hover) {
    &:hover {
      background: #f3f4f6;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  .point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: black;
    margin-right: 8px;
  }

  .number {
    font-size: 22px;
    line-height: 26px;
    margin-right: 4px;
  }

  .name {
    font-size: 16px;
    line-height: 20px;
    max-width: calc(100% - 98px);

    .userHeadset {
      color: #16a34a;
    }

    .myHeadset {
      color: #1d4ed8;
    }
  }

  .status {
    font-size: 14px;
    line-height: 16px;
    color: orange;

    &__active {
      color: green;
    }
  }

  .buttons {
    right: 10px;
    position: absolute;
    display: flex;
    align-items: center;

    .button {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.addUserButton {
  width: fit-content;
  padding: 6px 16px;
}

.table {
  width: calc(100% - 4px);
  margin: 1px;

  .notActive {
    background: #d1d5db;
  }
}

.tokenBlock {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .tokenButton {
    width: fit-content;
    padding: 6px 16px;
  }

  .tokenInput {
    cursor: pointer;
  }
}

.cteateConnectModal {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .buttonBlock {
    display: flex;
    gap: 8px;
  }
}
