.content {
  width: 350px;
  max-height: 700px;
  padding-bottom: 48px;
  position: relative;

  .members {
    margin: 10px 0 10px 0;
    max-height: 300px;
    overflow: auto;
    padding-bottom: 6px;
    border-bottom: 1px solid gray;
    font-size: 16px;

    & > div {
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;

      &:hover {
        background: #f3f4f6;
      }
    }
  }

  .selectMembers {
    margin-top: 10px;
    max-height: 250px;
    overflow: auto;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;

    & > div {
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: blue;
      padding: 8px;
      border-radius: 8px;
      cursor: pointer;
      gap: 4px;

      .icon {
        opacity: 0;
      }

      &:hover {
        background: #f0f9ff;

        .icon {
          opacity: 1;
        }
      }
    }
  }

  .buttons {
    width: calc(100% - 40px);
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
  }
}
