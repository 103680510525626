.item {
  margin-bottom: 24px;

  .date {
    display: flex;
    margin-top: 8px;

    .line {
      display: flex;
      align-items: center;
      font-size: 40px;
      margin: 0 18px;
    }
  }
}
