.modal {
  width: calc(100% - 40px - 20px);
  max-height: calc(100% - 40px - 20px);

  .content {
    width: 100%;
    height: calc(100% - 24px - 26px - 28px - 10px);

    .list {
      overflow: auto;
      max-height: 500px;

      .item {
        display: flex;
        margin-bottom: 10px;

        .inputBlock__fake {
          width: 47px;
          height: 19px;
          padding: 6px 14px;
        }

        .inputBlock {
          width: 45px;
          height: 17px;
          padding: 6px 14px;

          .input {
            height: 17px;
          }
        }

        .text {
          width: calc(100% - 95px);
          white-space: pre-line;
        }
      }
    }
  }

  .buttons {
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .button {
      width: calc(50% - 5px);

      &__red {
        color: red;
        border-color: red;
      }
    }
  }
}
