.content {
  width: 300px;

  .item {
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: #f3f4f6;
    }
  }
}

.buttons {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
